import {
  conform,
  list,
  requestIntent,
  useFieldList,
  useInputEvent,
} from '@conform-to/react'
import React, { type ReactNode, useId, useRef, useState } from 'react'
import { Input, type InputProps } from '~/components/ui/inputs/input.tsx'
import { Label } from '~/components/ui/inputs/label.tsx'
import {
  Checkbox,
  type CheckboxProps,
} from '~/components/ui/inputs/checkbox.tsx'
import {
  Textarea,
  type TextareaProps,
} from '~/components/ui/inputs/textarea.tsx'
import { RadioGroup, type RadioGroupProps } from './radio-group.tsx'
import { minutesToTimeType } from '~/utils/strings.ts'
import { ButtonTrigger } from '~/components/ui/inputs/button/Button.tsx'
import { cn } from '~/utils/misc.tsx'
import { Combobox, type ComboboxProps } from './combobox.tsx'
import {
  type DatePickerProps,
  DatePickerSelect,
} from './select/date-picker-select.tsx'
import { Select, type SelectProps } from './select/select.tsx'
import { StarsRating, type StarsRatingProps } from '../stars.tsx'
import { Icon } from '../icons/icon.tsx'

export type ListOfErrors = Array<string | null | undefined> | null | undefined

export function ErrorList({
  id,
  errors,
}: {
  errors?: ListOfErrors
  id?: string
}) {
  const errorsToRender = errors?.filter(Boolean)
  if (!errorsToRender?.length) return null
  return (
    <ul id={id} className="flex flex-col gap-1">
      {errorsToRender.map(e => (
        <li key={e} className="text-[10px] text-danger">
          {e}
        </li>
      ))}
    </ul>
  )
}

export function Field({
  labelProps,
  inputProps,
  errors,
  className,
}: {
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement> & {
    asChild?: boolean
  }
  inputProps: Partial<InputProps>
  errors?: ListOfErrors
  className?: string
}) {
  const fallbackId = useId()
  const id = inputProps.id ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined
  const variant = inputProps.variant ?? 'filled'
  return (
    <div className={className}>
      {labelProps && <Label htmlFor={id} {...labelProps} />}
      <Input
        variant={variant}
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        {...inputProps}
      />
      {errorId && (
        <div className=" px-4  pt-1">
          <ErrorList id={errorId} errors={errors} />
        </div>
      )}
    </div>
  )
}

export function TextareaField({
  labelProps,
  textareaProps,
  errors,
  className,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
  textareaProps: TextareaProps
  errors?: ListOfErrors
  className?: string
}) {
  const fallbackId = useId()
  const id = textareaProps.id ?? textareaProps.name ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined
  return (
    <div className={className}>
      <Label htmlFor={id} {...labelProps} />
      <Textarea
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        {...textareaProps}
      />
      {errorId && (
        <div className="px-4 pt-1">
          <ErrorList id={errorId} errors={errors} />
        </div>
      )}
    </div>
  )
}

export function CheckboxField({
  labelProps,
  checkboxProps: buttonProps,
  errors,
  className,
}: {
  labelProps?: JSX.IntrinsicElements['label']
  checkboxProps: CheckboxProps
  errors?: ListOfErrors
  className?: string
}) {
  const fallbackId = useId()
  const buttonRef = useRef<HTMLButtonElement>(null)
  // To emulate native events that Conform listen to:
  // See https://conform.guide/integrations
  const control = useInputEvent({
    // Retrieve the checkbox element by name instead as Radix does not expose the internal checkbox element
    // See https://github.com/radix-ui/primitives/discussions/874
    ref: () =>
      buttonRef.current?.form?.elements.namedItem(buttonProps.name ?? ''),
    onFocus: () => buttonRef.current?.focus(),
  })
  const id = buttonProps.id ?? buttonProps.name ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined
  return (
    <div className={className}>
      <div className="flex gap-2">
        <Checkbox
          id={id}
          ref={buttonRef}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId}
          {...buttonProps}
          onCheckedChange={state => {
            control.change(Boolean(state.valueOf()))
            buttonProps.onCheckedChange?.(state)
          }}
          onFocus={event => {
            control.focus()
            buttonProps.onFocus?.(event)
          }}
          onBlur={event => {
            control.blur()
            buttonProps.onBlur?.(event)
          }}
          type="button"
        />
        <label
          htmlFor={id}
          className="mb-0 self-center text-body-xs text-foreground-muted"
          {...labelProps}
        />
      </div>
      {errorId && (
        <div className="px-4 pt-1">
          <ErrorList id={errorId} errors={errors} />
        </div>
      )}
    </div>
  )
}

export type AutocompleteOption = {
  label: ReactNode
  labelTrigger?: ReactNode
  value: string
}

export function ComboboxField({
  labelProps,
  inputProps,
  errors,
  className,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
  inputProps: React.InputHTMLAttributes<HTMLInputElement> & ComboboxProps
  errors?: ListOfErrors
  className?: string
}) {
  const fallbackId = useId()
  const {
    id: inputId,
    options,
    optionsClassName,
    triggerClassName,
    leftIcon,
    onInputChange,
    ...otherInputProps
  } = inputProps
  const id = inputId ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined

  // to be able to trigger conform events, we use hidden input with shadowRef
  // more info https://conform.guide/integrations
  const [value, setValue] = useState(
    inputProps.defaultValue ?? inputProps.value ?? '',
  )

  const shadowInputRef = useRef<any>(null)
  const control = useInputEvent({
    ref: shadowInputRef,
    onReset: () => setValue(inputProps.defaultValue ?? inputProps.value ?? ''),
  })

  return (
    <div className={className}>
      <Label htmlFor={id} {...labelProps} />
      <input ref={shadowInputRef} {...otherInputProps} hidden={true} />
      <Combobox
        id={id}
        {...{ ...(inputProps as any), value, setValue }}
        onChange={value => {
          if (!value) return
          control.change(value)
          // inputProps.onChange?.(value as any)
        }}
        onBlur={control.blur}
        onFocus={control.focus}
      />
      {errorId && (
        <div className=" px-4  pt-1">
          <ErrorList id={errorId} errors={errors} />
        </div>
      )}
    </div>
  )
}

export function RadioGroupField({
  labelProps,
  inputProps,
  errors,
  className,
}: {
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>
  inputProps: RadioGroupProps & React.InputHTMLAttributes<HTMLInputElement>
  errors?: ListOfErrors
  className?: string
}) {
  const fallbackId = useId()
  const { id: inputId, ...otherInputProps } = inputProps
  const id = inputId ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined

  // to be able to trigger conform events, we use hidden input with shadowRef
  // more info https://conform.guide/integrations
  const shadowInputRef = useRef<any>(null)
  const control = useInputEvent({
    ref: shadowInputRef,
  })

  return (
    <div className={className}>
      <Label htmlFor={id} {...labelProps} />
      <input ref={shadowInputRef} {...otherInputProps} className="hidden" />
      <RadioGroup
        id={id}
        {...(inputProps as any)}
        onChange={value => {
          if (!value) return
          control.change(value)
          control.blur()
          inputProps.onChange?.(value as any)
        }}
        // onBlur={control.blur}
        onFocus={control.focus}
      />
      {errorId && (
        <div className=" px-4  pt-1">
          <ErrorList id={errorId} errors={errors} />
        </div>
      )}
    </div>
  )
}

export function DatePickerField({
  labelProps,
  inputProps,
  errors,
  className,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
  inputProps: React.InputHTMLAttributes<HTMLInputElement> & DatePickerProps
  errors?: ListOfErrors
  className?: string
}) {
  const fallbackId = useId()
  const { id: inputId, ...otherInputProps } = inputProps
  const id = inputId ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined

  // to be able to trigger conform events, we use hidden input with shadowRef
  // more info https://conform.guide/integrations
  const [value, setValue] = useState(
    inputProps.defaultValue ?? inputProps.value ?? '',
  )

  const shadowInputRef = useRef<any>(null)
  const control = useInputEvent({
    ref: shadowInputRef,
    onReset: () => setValue(''),
  })

  return (
    <div className={className}>
      <Label htmlFor={id} {...labelProps} />
      <input ref={shadowInputRef} {...otherInputProps} hidden={true} />
      <DatePickerSelect
        {...{ ...(inputProps as any), value }}
        onChange={date => {
          if (!date) return
          control.change(`${date}`)
          control.blur()
          inputProps.onChange?.(date as any)
        }}
        onFocus={control.focus}
      />
      {errorId && (
        <div className=" px-4  pt-1">
          <ErrorList id={errorId} errors={errors} />
        </div>
      )}
    </div>
  )
}

export function SelectField({
  labelProps,
  inputProps,
  errors,
  className,
}: {
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>
  inputProps: React.InputHTMLAttributes<HTMLInputElement> & SelectProps
  errors?: ListOfErrors
  className?: string
}) {
  const fallbackId = useId()
  const { id: inputId, leftIcon, ...otherInputProps } = inputProps
  const id = inputId ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined

  // to be able to trigger conform events, we use hidden input with shadowRef
  // more info https://conform.guide/integrations
  const [value, setValue] = useState(
    inputProps.defaultValue ?? inputProps.value ?? '',
  )

  const shadowInputRef = useRef<any>(null)
  const control = useInputEvent({
    ref: shadowInputRef,
    onReset: () => setValue(''),
  })

  return (
    <div className={className}>
      {labelProps && <Label htmlFor={id} {...labelProps} />}
      <input ref={shadowInputRef} {...otherInputProps} hidden={true} />
      <Select
        id={id}
        {...{ ...(inputProps as any), value }}
        onChange={(value: any) => {
          control.change(value)
          control.blur()
          inputProps.onChange?.(value as any)
        }}
        // onBlur={control.blur}
        onFocus={control.focus}
      />
      {errorId && (
        <div className=" px-4  pt-1">
          <ErrorList id={errorId} errors={errors} />
        </div>
      )}
    </div>
  )
}

export function StarsRatingField({
  labelProps,
  inputProps,
  errors,
  className,
}: {
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>
  inputProps: StarsRatingProps
  errors?: ListOfErrors
  className?: string
}) {
  const fallbackId = useId()
  const { id: inputId, size, onChange, ...otherInputProps } = inputProps
  const id = inputId ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined

  // to be able to trigger conform events, we use hidden input with shadowRef
  // more info https://conform.guide/integrations
  const [value, setValue] = useState(
    inputProps.defaultValue ?? inputProps.value ?? '',
  )

  const shadowInputRef = useRef<any>(null)
  const control = useInputEvent({
    ref: shadowInputRef,
    onReset: () => setValue(''),
  })

  return (
    <div className={className}>
      {labelProps && <Label htmlFor={id} {...labelProps} />}
      <input ref={shadowInputRef} {...otherInputProps} hidden={true} />
      <StarsRating
        id={id}
        editable
        {...{ ...(inputProps as any), value, setValue }}
        onChange={(value: number) => {
          control.change(String(value))
          inputProps.onChange?.(value as any)
        }}
        onBlur={control.blur}
        onFocus={control.focus}
      />
      {errorId && (
        <div className=" px-4  pt-1">
          <ErrorList id={errorId} errors={errors} />
        </div>
      )}
    </div>
  )
}

export function SelectBufferTimeField({
  inputProps,
  errors,
  className,
  selectProps,
  selectClassName,
}: {
  inputProps: Partial<InputProps>
  errors?: ListOfErrors
  className?: string
  selectClassName?: string
  selectProps: React.InputHTMLAttributes<HTMLInputElement> &
    Omit<SelectProps, 'defaultValue' | 'options'>
}) {
  const timeTypeOptions = [
    { label: 'Minutos', value: 'minutes' },
    { label: 'Horas', value: 'hours' },
    { label: 'Días', value: 'days' },
  ]

  const { time, type } = selectProps.value
    ? { time: undefined, type: undefined }
    : minutesToTimeType(parseInt(inputProps.defaultValue as any))
  return (
    <div className="flex gap-4">
      <Field
        {...inputProps}
        inputProps={{
          ...inputProps,
          defaultValue: time,
        }}
        className={className}
        errors={errors}
      />
      <SelectField
        className={cn('w-full max-w-[120px]', selectClassName)}
        inputProps={{
          ...selectProps,
          defaultValue: type,
          options: timeTypeOptions,
        }}
      />
    </div>
  )
}

export function QuestionsField({
  form,
  field,
  isSpecialService,
}: {
  form: any
  field: any
  isSpecialService: boolean
}) {
  const fieldList = useFieldList(form.ref, field)
  const handleAdd = () => {
    if (fieldList.length < 4) {
      requestIntent(form.ref.current, list.insert(field.name))
    }
  }
  return (
    <div className="flex flex-col gap-2">
      {fieldList.map((question, index) => (
        <>
          {question.defaultValue?.isFixed && (
            <input
              type="hidden"
              {...conform.input(question)}
              value={question.defaultValue?.question}
            />
          )}
          <Field
            key={question.key}
            inputProps={{
              ...conform.input(question),
              defaultValue: question.defaultValue?.question,
              placeholder: `Pregunta ${index + 1}`,
              variant: 'outlined',
              disabled: question.defaultValue?.isFixed,
            }}
            errors={question.errors}
          />
        </>
      ))}
      {fieldList.length < 4 && (
        <ButtonTrigger variant={'cancel'} onClick={handleAdd}>
          <Icon name="plus" className="mr-1" />
          Nueva pregunta
        </ButtonTrigger>
      )}
    </div>
  )
}

type Props = {
  form: any
  field: any
  inputPlaceholder?: string
  addLabel: string
  maxLength?: number
  inputClassName?: string
}
export function InputArrayField({
  form,
  field,
  inputPlaceholder = '',
  addLabel,
  maxLength,
  inputClassName,
}: Props) {
  const fieldList = useFieldList(form.ref, field)

  const handleAdd = () => {
    if (maxLength && fieldList.length > maxLength) return

    requestIntent(form.ref.current, list.insert(field.name))
  }

  return (
    <div className="flex flex-col gap-2">
      {fieldList.map((question, index) => (
        <Field
          key={question.key}
          inputProps={{
            ...conform.input(question),
            defaultValue: question.defaultValue,
            placeholder: `${inputPlaceholder} ${index + 1}`.trim(),
            variant: 'outlined',
            className: inputClassName,
          }}
          errors={question.errors}
        />
      ))}
      {maxLength && fieldList.length > maxLength ? null : (
        <ButtonTrigger variant={'cancel'} onClick={handleAdd}>
          <Icon name="plus" className="mr-1" />
          {addLabel}
        </ButtonTrigger>
      )}
    </div>
  )
}
