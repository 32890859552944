import * as React from 'react'

import { cn } from '~/utils/misc.tsx'

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  variant?: 'filled' | 'outlined'
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, variant = 'filled', ...props }, ref) => {
    return (
      <textarea
        className={cn(
          'b flex min-h-[80px] w-full rounded-md border border-input px-3 py-2 text-sm ring-offset-background placeholder:text-foreground-muted hover:border-secondary focus-visible:border-2 focus-visible:outline-none focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 aria-[invalid]:border-input-invalid',
          variant === 'filled' ? 'bg-background' : 'bg-white',
          className,
        )}
        ref={ref}
        {...props}
      />
    )
  },
)
Textarea.displayName = 'Textarea'

export { Textarea }
