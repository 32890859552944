import { Fragment, useEffect, useRef, useState } from 'react'
import { Combobox as ComboboxRoot, Transition } from '@headlessui/react'
import { Icon } from '../icons/icon.tsx'
import { Input } from '~/components/ui/inputs/input.tsx'
import {
  optionDefaultClassName,
  optionsDefaultClassName,
} from './select/select.tsx'
import { type AutocompleteOption } from './forms.tsx'
import { cn } from '~/utils/misc.tsx'

const setInitialValue = ({
  value,
  defaultValue,
  multiple,
}: Pick<ComboboxProps, 'value' | 'defaultValue' | 'multiple'>) => {
  if (value) return value
  if (defaultValue) return defaultValue
  return multiple ? [] : ''
}

export type ComboboxProps = {
  options: string[] | AutocompleteOption[]
  className?: string
  value?: string | string[] | null
  defaultValue?: string | string[]
  onChange?: (value: string) => void
  placeholder?: string | number
  multiple?: boolean
  optionsClassName?: string
  triggerClassName?: string
  variant?: 'outlined' | 'filled'
  leftIcon?: React.ReactNode
  autoComplete?: string
  disabled?: boolean
  onInputChange?: (value: string) => void
}

export const Combobox = ({
  options,
  value: propValue,
  onChange,
  className,
  placeholder = 'Selecciona...',
  multiple,
  defaultValue,
  optionsClassName,
  triggerClassName,
  variant = 'outlined',
  disabled,
  autoComplete,
  onInputChange,
}: ComboboxProps) => {
  const [value, setValue] = useState<string | string[]>(
    setInitialValue({ value: propValue, defaultValue, multiple }),
  )
  const [query, setQuery] = useState('')
  const isInitialRender = useRef(true)

  // trigger onChange on useEffect instead, to avoid a bug which doesn't close popover if update state on parent component onChange
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }
    if (onChange) {
      if (typeof value === 'string') {
        onChange(value)
      } else {
        onChange(value.join(','))
      }
    }
  }, [value])

  const filteredOptions =
    query === ''
      ? options
      : (options as any[]).filter((option: any) => {
          // filter by both, value and label
          const optionValue: string =
            typeof option === 'string' ? option : option.value
          const valueFound = optionValue
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
          if (typeof option === 'string') return valueFound

          const optionLabel: string =
            typeof option.label === 'string'
              ? option.label
              : option.labelTrigger
          if (typeof optionLabel !== 'string') return valueFound

          const labelFound = optionLabel
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
          return valueFound || labelFound
        })

  const handleChange = (currentValue: string | string[]) => {
    if (currentValue === value) return
    setValue(currentValue)
  }

  const getOptionValue = (option: string | AutocompleteOption) => {
    if (typeof option == 'string') {
      return option
    }
    return option.value
  }
  const getOptionLabel = (option: string | AutocompleteOption) => {
    if (typeof option == 'string') {
      return option
    }
    return option.label
  }

  function getLabelTrigger(value: string | string[]) {
    if (!value || value.length === 0) {
      return placeholder
    }
    if (typeof value != 'string') {
      // if array, convert array to string
      return value.join(', ')
    }
    const selectedOption = (options as any).find((option: any) => {
      if (typeof option == 'string') {
        return option === value
      }
      return option.value === value
    })
    if (typeof selectedOption == 'string') {
      return selectedOption
    }

    return selectedOption?.labelTrigger ?? selectedOption?.label
  }

  return (
    <ComboboxRoot
      defaultValue={value ? value : undefined}
      onChange={handleChange}
      disabled={disabled}
    >
      <div className={cn('relative w-full', className)}>
        <ComboboxRoot.Button
          className={cn(
            'relative w-full cursor-default overflow-hidden',
            triggerClassName,
          )}
        >
          <ComboboxRoot.Input
            autoComplete={autoComplete ?? 'off'}
            as={Input}
            className={variant === 'filled' ? 'bg-background' : ''}
            placeholder="Selecciona..."
            displayValue={() => getLabelTrigger(value)}
            onChange={event => {
              setQuery(event.target.value)
              onInputChange && onInputChange(event.target.value)
            }}
          />
          <div className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-2">
            <Icon
              name="chevron-down"
              className="h-4 w-4 text-gray-400"
              aria-hidden="true"
            />
          </div>
        </ComboboxRoot.Button>
        <Transition
          as={Fragment}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          afterLeave={() => setQuery('')}
        >
          <ComboboxRoot.Options
            className={cn(optionsDefaultClassName, optionsClassName)}
            hold
          >
            {filteredOptions.length === 0 && query !== '' ? (
              <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                Nothing found.
              </div>
            ) : (
              filteredOptions.map(option => (
                <ComboboxRoot.Option
                  key={getOptionValue(option)}
                  className={optionDefaultClassName}
                  value={getOptionValue(option)}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {getOptionLabel(option)}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3`}
                        >
                          <Icon
                            name="check"
                            className={`h-5 w-5 text-accent-400`}
                            aria-hidden="true"
                          />
                        </span>
                      ) : null}
                    </>
                  )}
                </ComboboxRoot.Option>
              ))
            )}
          </ComboboxRoot.Options>
        </Transition>
      </div>
    </ComboboxRoot>
  )
}
