import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { Circle } from 'lucide-react'
import React from 'react'

import { cn } from '~/utils/misc.tsx'
import { type AutocompleteOption } from './forms.tsx'
import { Label } from '~/components/ui/inputs/label.tsx'

const RadioGroupRoot = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn('grid gap-2', className)}
      {...props}
      ref={ref}
    />
  )
})
RadioGroupRoot.displayName = RadioGroupPrimitive.Root.displayName

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, children, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        'focus-visible:ring-ring aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
        className,
      )}
      {...props}
    >
      <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
        <Circle className="h-2.5 w-2.5 fill-current text-current" />
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  )
})
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName

export type RadioGroupProps = {
  className?: string
  value?: string
  defaultValue?: string
  onChange?: (value: string) => void
  onFocus?: () => void
  options: string[] | AutocompleteOption[]
}

const RadioGroup = ({
  options,
  value: propValue,
  defaultValue,
  onChange,
  className,
}: RadioGroupProps) => {
  const [value, setValue] = React.useState<string>(
    defaultValue || propValue || '',
  )

  const handleChange = (currentValue: string) => {
    if (currentValue === value) return
    setValue(currentValue)

    if (onChange) {
      onChange(currentValue)
    }
  }

  const getOptionValue = (option: string | AutocompleteOption) => {
    if (typeof option == 'string') {
      return option
    }
    return option.value
  }
  const getOptionLabel = (option: string | AutocompleteOption) => {
    if (typeof option == 'string') {
      return option
    }
    return option.label
  }

  return (
    <RadioGroupRoot
      defaultValue={value}
      onValueChange={handleChange}
      className={cn('flex gap-4 sm:gap-8', className)}
    >
      {options.map(option => (
        <div
          className="flex items-center gap-1 whitespace-nowrap sm:gap-3"
          key={getOptionValue(option)}
        >
          <RadioGroupItem
            value={getOptionValue(option)}
            id={getOptionValue(option)}
          />
          <Label htmlFor={getOptionValue(option)} className="mb-0">
            {getOptionLabel(option)}
          </Label>
        </div>
      ))}
    </RadioGroupRoot>
  )
}

export { RadioGroupRoot, RadioGroupItem, RadioGroup }
